<template>
  <div class="table-node" v-for="(item, index) in list" :key="index">
    <operation-list
      :node="item"
      :unEdit="unEdit"
      :parentNode="list"
      :parentKey="index"
      :max="max"
      :id="item.level + '_' + index"
    ></operation-list>
    <div v-if="!unEdit">
      <PlusSquareFilled
        title="新增项"
        :style="{ fontSize: '32px', color: '#eee', cursor: 'pointer' }"
        @click="handleAddBigItem(index)"
      />
      <MinusSquareFilled
        v-show="list.length > 1"
        title="删除项"
        :style="{ fontSize: '32px', color: '#eee', cursor: 'pointer' }"
        @click="handledeleteBigItem(index)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, provide, ref, watch } from 'vue'
import operationList from './operationList.vue'
import { PlusSquareFilled, MinusSquareFilled } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: { operationList, PlusSquareFilled, MinusSquareFilled },
  props: { data: Array, max: Number, unEdit: Boolean },
  setup(props) {
    const isPass = ref(true)
    const _items = {
      content: '',
      file_paths: [],
      level: 1,
      visible: false
    }
    const _obj = reactive({
      list: [
        {
          content: '',
          file_paths: [],
          level: 1,
          visible: false
        }
      ]
    })
    const updateList = () => {
      console.log(_obj.list, 1)
    }
    const checkNode = node => {
      for (let index = 0; index < node.length; index++) {
        if (node[index].content.trim().length === 0) {
          isPass.value = false
        }
        if (node[index].items) {
          checkNode(node[index].items)
        }
      }
    }
    const handleCheckNode = () => {
      checkNode(_obj.list)
      if (isPass.value === false) {
        message.error('选项有空项,请先填写内容')
        isPass.value = true
        return false
      }
      return true
    }
    const handleAddBigItem = index => {
      if (!handleCheckNode()) return
      _obj.list.splice(index + 1, 0, JSON.parse(JSON.stringify(_items)))
      updateList()
    }
    const handledeleteBigItem = index => {
      _obj.list.splice(index, 1)
      updateList()
    }
    const getList = (arr = ['content', 'file_paths', 'items']) => {
      if (_obj.list.length === 1 && _obj.list[0].content.trim().length === 0) {
        return []
      }
      return JSON.parse(JSON.stringify(_obj.list, arr))
    }
    provide('updateList', updateList)
    provide('handleCheckNode', handleCheckNode)
    // 添加等级
    const initData = (node, level = 1) => {
      for (let index = 0; index < node.length; index++) {
        if (!node[index].file_paths) {
          node[index].file_paths = []
        }
        if (node[index].items) {
          node[index].level = level
          const _level = level + 1
          initData(node[index].items, _level)
        } else {
          node[index].level = level
        }
      }
    }
    watch(
      () => props.data,
      newValue => {
        if (
          (!props.data || !props.data.toString()) &&
          (!newValue || !newValue.toString())
        ) {
          return false
        }
        _obj.list = JSON.parse(JSON.stringify(newValue))
        initData(_obj.list)
      }
    )
    return {
      ...toRefs(_obj),
      handleAddBigItem,
      handledeleteBigItem,
      handleCheckNode,
      getList
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .list-box:first-child {
  background: #f7fbfc;
  padding: 10px;
}
</style>
