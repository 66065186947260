<template>
  <div class="list-box mb-5">
    <div class="flex flex-align-top mb-5">
      <!-- 加号 -->
      <template v-if="!unEdit">
        <PlusSquareOutlined
          v-show="node.level > 1"
          title="新增兄弟项"
          :style="{ fontSize: '36px', color: '#eee', cursor: 'pointer' }"
          @click="handleAddBrotherItem(parentNode, node.level, parentKey)"
        />
        <!-- 减号 -->
        <MinusSquareOutlined
          v-show="node.level > 1"
          title="删除当前项"
          :style="{
            fontSize: '36px',
            color: '#eee',
            cursor: 'pointer'
          }"
          @click="handleDeleteItem(node, parentNode, parentKey)"
        />
      </template>
      <div class="flex-1">
        <div class="flex flex-align-c flex-1">
          <a-input
            placeholder="请输入项"
            :maxlength="100"
            autocomplete="off"
            :value="node.content"
            @change="changeInputValue(node, $event)"
            :disabled="unEdit"
          />
          <!-- 上传附件 -->
          <div class="ml-5" v-show="!unEdit">
            <upload-ab
              ref="uploadReImg"
              :id="id"
              @changeFile="changeFile($event, node)"
              :list="node.file_paths"
              :upObj="SEND_TYPE"
              :isAuto="true"
              @autoCallback="handleUploadCallback($event, node)"
              >上传附件
            </upload-ab>
          </div>
          <!-- 预览附件 -->
          <a-badge
            :count="node.file_paths.length"
            :number-style="{ backgroundColor: '#40a9ff' }"
          >
            <a-button class="ml-5" @click="handleView(node)">
              预览附件
            </a-button>
          </a-badge>
          <!-- 加号 -->
          <PlusSquareOutlined
            v-show="!unEdit"
            title="新增子项"
            :style="{
              fontSize: '36px',
              color: '#eee',
              cursor: 'pointer',
              'margin-left': '3px'
            }"
            @click="handleAddSmallItem(node)"
          />
        </div>
        <div
          class="img-list-box flex"
          v-show="node.visible && node.file_paths.length"
        >
          <div
            class="img-box"
            v-for="(items, index) in node.file_paths"
            :key="index"
          >
            <a-image :src="OSSURL + '/' + items" />
            <CloseSquareOutlined
              v-show="!unEdit"
              class="icon-close"
              @click="handleDeleteFile(node, index)"
            />
          </div>
        </div>
      </div>
    </div>
    <template v-for="(item, index) in node.items" :key="index">
      <operation-list
        :unEdit="unEdit"
        :node="item"
        :max="max"
        :parentNode="node.items"
        :parentKey="index"
        :id="id + '_' + index"
        :style="{ 'padding-left': item.level + 10 + 'px' }"
      ></operation-list>
    </template>
  </div>
</template>

<script>
import { defineComponent, inject } from 'vue'
import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  CloseSquareOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { OSSURL } from '@/hooks/ali-oss'
import uploadAb from '@/views/encyclopediasManger/components/upload-ab'
const SEND_TYPE = [
  {
    lable: '图片',
    filter: '.png.jpg',
    bunket: 'image', // 必传
    maxNum: 20,
    size: 10,
    type: 'p1',
    isRealName: true // 是否重命名
  }
]
export default defineComponent({
  name: 'operationList',
  props: {
    node: {},
    parentNode: {},
    parentKey: {},
    max: {},
    unEdit: Boolean,
    id: {}
  },
  components: {
    PlusSquareOutlined,
    MinusSquareOutlined,
    uploadAb,
    CloseSquareOutlined
  },
  setup(props, ctx) {
    const updateList = inject('updateList')
    const handleCheckNode = inject('handleCheckNode')
    const _items = {
      content: '',
      file_paths: [],
      visible: false
    }
    const handleAddBrotherItem = (parentNode, level, parentIndex) => {
      if (!handleCheckNode()) return
      parentNode.splice(
        parentIndex + 1,
        0,
        JSON.parse(JSON.stringify(Object.assign(_items, { level })))
      )
      updateList()
    }
    const handleAddSmallItem = node => {
      if (!handleCheckNode()) return
      if (node.level === props.max) {
        message.warning('已到最大子节点,不能再添加子节点')
        return
      }
      if (!Object.prototype.hasOwnProperty.call(node, 'items')) {
        node.items = [
          JSON.parse(
            JSON.stringify(Object.assign(_items, { level: node.level + 1 }))
          )
        ]
      } else {
        node.items.push(
          JSON.parse(
            JSON.stringify(Object.assign(_items, { level: node.level + 1 }))
          )
        )
      }
      updateList()
    }
    const changeInputValue = (node, e) => {
      node.content = e.target.value
    }
    const handleDeleteItem = (node, parentNode, keyIndex) => {
      parentNode.splice(keyIndex, 1)
      updateList()
    }
    // 预览资源
    const handleView = node => {
      if (node.file_paths.length === 0) {
        message.warning('还没有附件可供预览')
        return false
      }
      node.visible = !node.visible
    }
    // 选择资源的回调
    const changeFile = (list, node) => {
      node.visible = true
    }
    // 自动上传成功后的回调函数(特殊处理图片列表)
    const handleUploadCallback = (list, node) => {
      console.log(list, node, 123)
      node.file_paths = list
    }
    // 删除文件
    const handleDeleteFile = (node, index) => {
      node.file_paths.splice(index, 1)
    }
    return {
      handleAddBrotherItem,
      handleAddSmallItem,
      changeInputValue,
      handleDeleteItem,
      OSSURL,
      handleView,
      SEND_TYPE,
      changeFile,
      handleUploadCallback,
      handleDeleteFile
    }
  }
})
</script>

<style lang="scss" scoped>
.list-box {
  .ant-input {
    border-color: #eee;
    &:focus {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 2px 0px #1890ff;
    }
  }
}
.flex-1 {
  flex: 1;
}
.img-list-box {
  flex-wrap: wrap;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border-top: none;
  .img-box {
    width: 20%;
    margin: 10px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.2);
    ::v-deep .ant-image {
      width: 100%;
    }
    ::v-deep .ant-image-img {
      width: 100%;
      height: 120px !important;
    }
  }
}
.icon-close {
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #155fef;
  }
}
</style>
